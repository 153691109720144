import React from "react";

export const HeroComponent = () => {


  return(
    <div className="row mt-3">
      <div className="col">
        <img src='/tellus.png' width='100%'/>
      </div>
    </div>
  )

}