import { useParams } from "react-router-dom"
import { useSurvey } from "../hooks/useSurveyContext"

export const languages = [
  {"code": "da-DK", "description": "Dansk"},
  {"code": "de-DE", "description": "Deutsch"},
  {"code": "et-EE", "description": "Eesti"},
  {"code": "en-GB", "description": "English"},
  {"code": "fi-FI", "description": "Suomi"},
  {"code": "fr-BE", "description": "French-Belgium"},
  {"code": "nl-BE", "description": "Dutch-Belgium"},
  {"code": "nl-NL", "description": "Dutch"},
  {"code": "nn-NO", "description": "Norsk"},
  {"code": "pl-PL", "description": "Polski"},
  {"code": "sv-SE", "description": "Svenska"}
]

export const LanguagePickerComponent = () => {
  const { languagecode } = useParams()
  const { language, setLanguage } = useSurvey()

  
  return(
    <div className="language-picker">
      <select onChange={(e) => {setLanguage(e.target.value)}} defaultValue={languagecode}>
        {languages.map((lang, i) => <option key={`lang-option-${i}-${lang.code}`} value={lang.code}>{lang.description}</option> )}
      </select>
    </div>
  )
}