import React from 'react'
import {SurveyComponent}  from './Components/SurveyComponent'
import {LandingPageComponent} from './Components/LandingPageComponent'
import {ThankYouComponent} from './Components/ThankYouComponent'
import './styles/tellus.scss'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {SurveyProvider} from './hooks/useSurveyContext'
import { UnsubscribeComponent } from './Components/UnsubscribeComponent'
import { UnsubscribeConfirmedComponent } from './Components/UnsubscribeConfirmedComponent'


function App() {
  return (
    <SurveyProvider>
      <Router>
        <Routes>
          <Route path='/' element={<LandingPageComponent />} />

          <Route path=':languagecode/survey/:token' element={<SurveyComponent />} />

          <Route path=':languagecode/SurveyCompleted' element={<ThankYouComponent />} />

          <Route path=':languagecode/Unsubscribe/:email/:token' element={<UnsubscribeComponent />} />

          <Route path=':languagecode/UnsubscribeConfirmed/' element={<UnsubscribeConfirmedComponent />} />
        </Routes>
      </Router>
    </SurveyProvider>
  );
}

export default App;
