import React from 'react'
import { useTranslation } from 'react-i18next'
import { HeroComponent } from './HeroComponent'


export const LandingPageComponent = () => {
    const { t, i18n } = useTranslation()

    return (
      <>
        <div className='container grey'>
            <HeroComponent />
            <div className='container-fluid white my-3'>
                <div className='text-center h-75 p-6'>
                    <div>
                        <h2>{t('InvalidLink')}</h2>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
}



