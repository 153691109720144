import React, {useEffect, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HeroComponent } from "./HeroComponent";
import { useTranslation } from 'react-i18next';
import { useSurvey } from "../hooks/useSurveyContext";
import { GetVariable } from '../configscripts';

export const UnsubscribeComponent = () => {
  const [validationMessage, setValidationMessage] = useState(false)
  const {languagecode, email, token} = useParams()
  const { setLanguage } = useSurvey()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {t, i18n} = useTranslation()

  useEffect(()=>{
    setLanguage(languagecode)
  },[languagecode])
  
  const confirmUnsubscription = async() => {
    setLoading(true)
    const rawResponse = await fetch(`${GetVariable('REACT_APP_EXTERNAL_API_URL')}/api/unsubscribe/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`
      }})
  
      const result = await rawResponse.json()

      if(result?.isOk){ 
        navigate(`/${languagecode}/UnsubscribeConfirmed`, { replace: true })
      }

      if(result?.Errors){
        setValidationMessage(result.Errors?.Responses?.join(' '))
      }
      setLoading(false)
  }

  return(
    <>
      <div className='container grey'>
        <HeroComponent />
        <div className='container-fluid white my-3' >
          <div className='row'>
            <div className='col p-6'>
                <h2>{t("Unsubscribe/Text").replace('{clientEmail}', email)}</h2>
            </div>
          </div>
          <div className="row">
          <div className="col-10">
            {validationMessage && 
              <div className="alert alert-danger">{validationMessage}</div>
            }
          </div>
          <div className="col-2">
              {loading ? 
                <button disabled className="btn btn-block btn-success mb-3">
                  <div className="spinner-border spinner-border-sm text-light" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </button> :
                <button className="btn btn-block btn-success mb-3" onClick={()=> confirmUnsubscription()}>{t("Unsubscribe/BtnConfirm")}</button>
              }
          </div>
        </div>
      </div>
    </div>
  </>
  )
}