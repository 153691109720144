import React, { useState, createContext, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { languages } from '../Components/LanguagePickerComponent';
import { GetVariable } from '../configscripts';

const surveyContext = createContext()
const answerContext = createContext()

export function useSurvey(){
    return useContext(surveyContext)
}

export function useAnswers(){
    return useContext(answerContext)
}

const callApi = async (endpoint, token) => {
  try{
    console.log(process.env)
    const rawResponse = await fetch(`${GetVariable('REACT_APP_EXTERNAL_API_URL')}/api/${endpoint}`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
      }
    })
    const result = await rawResponse.json()
    return result
  } catch(error) {
    console.log(error)
    return false
  }
}

export function SurveyProvider({children}){
    const [language, setLanguage] = useState('en-GB')
    const [t, i18n] = useTranslation()
    const [survey, setSurvey] = useState()
    const [token, setToken] = useState()
    const [questionGroups, setQuestionGroups] = useState()
    const [questionsAndTranslations, setQuestionsAndTranslations] = useState()
    const [error, setError] = useState()

    useEffect(()=>{
      if(languages.map(l => l.code).includes(language)){
        const lang = language.replace('-','')
        i18n.changeLanguage(lang)
        translateQuestions()
      }
    },[language])

    useEffect(() => {
      if(token === null || token === undefined){
        return
      }
      const getsurvey = async () => {
          const tokenIsValid = await callApi('token/isValid', token)
          
          if(tokenIsValid == false){
            setError('InvalidLink')
          
            return
          }
          
          const surveyIsAnswered = await callApi('survey/isanswered', token)

          if(surveyIsAnswered?.isAnswered){
            setError('SurveyAnswered')
            return
          }

          const survey = await callApi('survey', token)

          setSurvey(survey)

          const questionsAndTranslations = await callApi('questions', token)
          setQuestionsAndTranslations(questionsAndTranslations)  
          
          let grps = questionsAndTranslations.QuestionGroups.filter(g => g.SurveyType.Id == survey.surveyTypeId && g.Questions.map(gq => gq.Id).some(r => survey.questions.includes(r)))
          
          grps.forEach(g => {
            g.InformationText = questionsAndTranslations.QuestionGroupTranslations.find(t => t.LanguageCodeId == language && t.RelatedId == g.Id)?.TextValue
            g.Questions = g.Questions.filter(q => survey.questions.includes(q.Id))
            g.Questions.forEach(q => 
                q.QuestionText = questionsAndTranslations.QuestionTranslations.find(t => t.LanguageCodeId == language && t.RelatedId == q.Id)?.TextValue
              )
          })

          setQuestionGroups(grps)

      };
      try {
        getsurvey()
      } catch(error) {
        console.log(error)
        setError('GeneralError')
      }
    }, [token])

    const translateQuestions = () => {
      let grps = questionsAndTranslations?.QuestionGroups.filter(g => g.SurveyType.Id == survey.surveyTypeId && g.Questions.map(gq => gq.Id).some(r => survey.questions.includes(r)))
          
      grps?.forEach(g => {
        g.InformationText = questionsAndTranslations.QuestionGroupTranslations.find(t => t.LanguageCodeId == language && t.RelatedId == g.Id)?.TextValue
        g.Questions = g.Questions.filter(q => survey.questions.includes(q.Id))
        g.Questions.forEach(q => 
            q.QuestionText = questionsAndTranslations.QuestionTranslations.find(t => t.LanguageCodeId == language && t.RelatedId == q.Id)?.TextValue
          )
      })

      setQuestionGroups(grps)
    }

    const [answers, setAnswers] = useState([])
    const [unansweredRequiredQuestions, setUnansweredRequiredQuestions] = useState()
    const updateAnswer = (id, value) => {
      var q = answers.find(a => a.questionId == id);
      if(q){
        setAnswers([...answers].map(object => {
          if(object?.questionId == id) {
            return {
              ...object,
              value: new String(value),
            }
          }
          else return object;
        }))
      }
      else{
        setAnswers([...answers, {questionId:id, value:new String(value)}])
      }

      if(unansweredRequiredQuestions?.map(q => q.Id).includes(id)){
        setUnansweredRequiredQuestions(unansweredRequiredQuestions.filter(q => q.Id !== id))
      }

    }

    const sendAnswers = async () => {
      try {
        let allQuestions = []
        questionGroups.forEach(g => g.Questions.forEach(q => allQuestions.push(q)))
        let unAnswered = []
  
        allQuestions.forEach(q => {
          if(!answers?.map(a => a.questionId).includes(q.Id)){
            unAnswered.push(q)
          }
        })
        
        if(unAnswered?.filter(q => q.IsRequired)?.length > 0) {
          setUnansweredRequiredQuestions(unAnswered?.filter(q => q.IsRequired && !answers?.map(a => a.Id).includes(q.Id)))
          return {IsOk:false, validationError:"FillRequiredFields"}
        }
  
        var requestBody = JSON.stringify(
          {
              "languageCode": "en-GB",
              "responses": answers
          })
  
        const rawResponse = await fetch(`${GetVariable('REACT_APP_EXTERNAL_API_URL')}/api/survey/respond`, {
        method: 'POST',
        body: requestBody,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${token}`
        }})
  
        const result = await rawResponse.json();
  
        return result

      } catch(error) {
        return {IsOk:false, validationError:"GeneralError"}
      }
    }



    return(
        <surveyContext.Provider value={{setToken:setToken, setLanguage:setLanguage, questionGroups:questionGroups, survey:survey, error:error}}>
            <answerContext.Provider value={{answers:answers, updateAnswer:updateAnswer, sendAnswers:sendAnswers, unanswered:unansweredRequiredQuestions}}>
                {children}
            </answerContext.Provider>
        </surveyContext.Provider>
    )
}