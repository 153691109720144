import React, { useState } from "react";
import { useAnswers, useSurvey } from "../hooks/useSurveyContext";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {browserHistory} from 'react-router'


export const SubmitButton = () => {
  const {questionGroups} = useSurvey()
  const {answers, sendAnswers} = useAnswers()
  const [loading, setLoading] = useState(false)
  const {t, i18n} = useTranslation()
  const navigate = useNavigate()
  const { languagecode } = useParams()
  
  const [validationMessage, setValidationMessage] = useState(false)
  
  const submitFeedBack = async () => {
    setLoading(true)

    const result = await sendAnswers()

    if(result?.isOk){ 
      navigate(`/${languagecode}/SurveyCompleted`, { replace: true })
    }

    if(result?.validationError){
      setValidationMessage(t(result?.validationError))
    }

    //bad status returned from api
    if(result?.errors){
      setValidationMessage(t('GeneralError'))
    }
    
    setLoading(false)
  }

  return (
    <>
      <div className="row">
        <div className="col">
          {validationMessage && 
            <div className="alert alert-danger">{validationMessage}</div>
          }
        </div>
      </div>
      <div className="row">
        <div className="col submit-button-container">
          {loading ? 
            <button disabled className="btn btn-success mb-3">
              <div className="spinner-border spinner-border-sm text-light" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button> :
            <button className="btn btn-success mb-3 py-3" onClick={()=> submitFeedBack()}>{t("SubmitSurvey")}</button>
          }
        </div>
      </div>
    </>
  )
}