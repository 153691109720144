import React,{useEffect, useState} from "react";
import { useAnswers } from "../hooks/useSurveyContext";
import { useTranslation } from 'react-i18next';

export const QuestionGroupComponent = ({ questionGroup, i }) => {
  const {answers, unanswered} = useAnswers()
  useEffect(()=>{
  },[unanswered, answers])

  return (
      <div key={questionGroup.Id + '-' + questionGroup.Name + '-' + i} >
      <h4>{questionGroup?.InformationText}</h4>
      {questionGroup?.Questions?.map((question,i) => {
          return (
              <div key={question.Id + '-' + question.SurveyType.Name + '-' + i} className={`${unanswered?.map(u => u.Id).includes(question.Id) && !answers?.map(a => a.Id).includes(question.Id) ? 'question-validation-error' : ''}`}>
              {question.ResponseType.Id == 1 &&
                  <div className={`question m-6`}>
                      <h5>{question?.QuestionText}</h5>
                      <div className='score-row-container'>
                          <ScorePicker questionId={question?.Id} isNps={question?.IsNps} />
                      </div>
                  </div>
              }
              { question.ResponseType.Id == 2 && 
                  <div className='question m-6'>
                      <h5>{question?.QuestionText}</h5>
                      <div className="pb-1">
                          <CommentBox questionId={question?.Id} />
                      </div>
                  </div>
              }
            </div>
          )
      })}
    </div>
  )
}

const ScorePicker = ({ questionId, isNps }) => {
  const {answers, updateAnswer} = useAnswers()
  const { t, i18n } = useTranslation();
  const colors = isNps == true ? ['#791E16', '#8c2319', '#b33229', '#c44d29', '#d9622b', '#e0853a', '#F59B00', '#ffde4d', '#F1ED59', '#7fb04c', '#548f24'] : ['#393F4D', '#474F60', '#545F73', '#626E85', '#707E98', '#7E8DAB', '#8997B2', '#94A0B9', '#9EAAC0', '#A9B3C7', '#B4BDCE'].reverse()
  //const colors = ['#791E16', '#8c2319', '#b33229', '#c44d29', '#d9622b', '#e0853a', '#F59B00', '#ffde4d', '#F1ED59', '#7fb04c', '#548f24']
  const [selected, setSelected] = useState()
  const handleSelect = (id, value) => {
    updateAnswer(id, value)
    setSelected(value)
  }
  
  return(
    <>
        <div className='row scorepicker ml-1'>
            {colors.map((c, i) => {
                return(
                    <div key={'scores-' + questionId + '-' + i} className="score-container m-1">
                        <div className={`score ${selected == i ? 'score-selected':''}`} style={{backgroundColor: c}} onClick={() => handleSelect(questionId, i)}>{i}</div>
                    </div>
                )
            })}
            </div>
          {isNps &&
            <div className='ml-1 score-info'>
                <span><small className='font-weight-bold'>{t('NotLikely')}</small></span>
                <span><small className='font-weight-bold'>{t('VeryLikely')}</small></span>
            </div>
            }
    </>
  )
}

const CommentBox = ({ questionId }) => {
    const { answers, updateAnswer } = useAnswers()

    return (
        <div className='row'>
            <textarea rows={6} style={{width:'100%'}} onChange={(e)=>(updateAnswer(questionId, e.target.value))}></textarea>
        </div>
    )
}