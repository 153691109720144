import i18n from "i18next";
import XHR from "i18next-xhr-backend";

import translationEnGB from "./locales/enGB/translation.json";
import translationSvSE from "./locales/svSE/translation.json";
import translationDaDK from "./locales/daDK/translation.json";
import translationDeDE from "./locales/deDE/translation.json";
import translationFrBE from "./locales/frBE/translation.json";
import translationNlBE from "./locales/nlBE/translation.json";
import translationNlNL from "./locales/nlNL/translation.json";
import translationNnNO from "./locales/nnNO/translation.json";
import translationPlPL from "./locales/plPL/translation.json";
import translationfiFI from "./locales/fiFI/translation.json";
import translationetEE from "./locales/etEE/translation.json";

i18n
  .use(XHR)
  .init({

    fallbackLng: "enGB", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    resources: {
      enGB: {
        translations: translationEnGB
      },
      svSE:{
        translations: translationSvSE
      },
      daDK:{
        translations: translationDaDK
      },
      deDE:{
        translations: translationDeDE
      },
      fiFI:{
        translations: translationfiFI
      },
      frBE:{
        translations: translationFrBE
      },
      nlBE:{
        translations: translationNlBE
      },
      nlNL:{
        translations: translationNlNL
      },
      nnNO:{
        translations: translationNnNO
      },
      plPL:{
        translations: translationPlPL
      },
      etEE:{
        translations: translationetEE
      }
    },
    ns: ["translations"],
    defaultNS: "translations"
  });

export default i18n;