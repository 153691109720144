import React, { useEffect } from "react";
import { HeroComponent } from './HeroComponent';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { useSurvey } from "../hooks/useSurveyContext";

export const ThankYouComponent = () => {
  const {t, i18n} = useTranslation()
  const { setLanguage } = useSurvey()
  const { languagecode } = useParams()
  
  useEffect(()=>{
    setLanguage(languagecode)
  },[languagecode])

  return(
    <>
    <div className='container grey'>
        <HeroComponent />

        <div className='container-fluid white my-3' >
            <div className='row'>
                <div className='col p-6'>
                    <h2>{t("HeaderThanks")}</h2>
                    <p>{t("ThanksText")}</p>
                </div>
            </div>
        </div>
    </div>

</>
  )

}