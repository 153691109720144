import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSurvey, useAnswers } from '../hooks/useSurveyContext'
import { HeroComponent } from './HeroComponent';
import { QuestionGroupComponent } from './QuestionGroupComponent';
import { SubmitButton } from './SubmitButton';
import { useTranslation } from 'react-i18next';
import { LanguagePickerComponent } from './LanguagePickerComponent';

export const SurveyComponent = () => {
    const { languagecode, token } = useParams()
    const { setToken, setLanguage, questionGroups, survey, error } = useSurvey()
    const [ loading, setLoading ] = useState(true)
    const { t, i18n } = useTranslation()
    
    useEffect(()=>{
        if(languagecode){
            setLanguage(languagecode)
        }
        setToken(token)
    },[token])

    useEffect(()=>{
        if(survey?.projectName != undefined || error){
            setLoading(false)
        }
    },[questionGroups, survey, error])
    
    if (loading) {
        return (
            <>
                <div className='container grey'>
                    <HeroComponent />
                    <div className='container-fluid white my-3' >
                        <div className='row'>
                            <div className='col p-6'>
                                <h2>{t("WelcomeHeader")}</h2>
                            </div>
                        </div>
                        <div className='text-center h-75'>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }

    if(error){
        return (
            <>
                <div className='container grey'>
                    <HeroComponent />
                    <div className='container-fluid white my-3'>
                        <div className='text-center h-75 p-6'>
                            <div>
                                <h2>{t(error)}</h2>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
   
    return (
        <>
            <div className='container grey'>
                <HeroComponent />

                <div className='container-fluid white my-3' >
                    <LanguagePickerComponent />
                    <div className='row'>
                        <div className='col p-6'>
                        <h2>{t("WelcomeHeader")}</h2>
                        <h4>{t("WelcomeText").replace('{projectName}', survey?.projectName)}</h4>
                            {questionGroups?.map((group, i) => {
                                return(
                                    <QuestionGroupComponent questionGroup={group} i={i} />
                                )
                            })}
                        </div>
                    </div>
                </div>
                <SubmitButton />
            </div>

        </>
    )
}